var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0"},[_c('h3',[_vm._v(_vm._s(_vm.groupInfo.name)+" / "+_vm._s(_vm.groupInfo.group_name)+" / "+_vm._s(_vm.groupInfo.hour_name)+" / "+_vm._s(_vm.groupInfo.lang_name)+" / "+_vm._s(_vm.groupInfo.ages)+" лет")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showAddFreeSeatsDialog($event)}}},[_vm._v("Добавить свободные места")])],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.addFreeSeatsDialog),callback:function ($$v) {_vm.addFreeSeatsDialog=$$v},expression:"addFreeSeatsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline lighten-2"},[_vm._v("Добавить новые свободные места")]),_c('v-card-text',[_c('v-form',{ref:"addFreeSeatsForm",staticClass:"form"},[_c('v-select',{attrs:{"items":_vm.freeSeatsTypeList,"item-text":"name","item-value":"id","rules":[function (v) { return typeof v !== 'object' || 'Required!'; }],"label":"Тип свободных мест"},model:{value:(_vm.freeSeatsTypeSelected),callback:function ($$v) {_vm.freeSeatsTypeSelected=$$v},expression:"freeSeatsTypeSelected"}}),(_vm.freeSeatsTypeSelected && _vm.freeSeatsTypeSelected != 1)?[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата","readonly":"","prepend-icon":"mdi-calendar","rules":[function (v) { return !!v || 'Required!'; }],"required":""},model:{value:(_vm.freeSeatsDate),callback:function ($$v) {_vm.freeSeatsDate=$$v},expression:"freeSeatsDate"}},'v-text-field',attrs,false),on))]}}],null,false,2000611689),model:{value:(_vm.datePickerMenu),callback:function ($$v) {_vm.datePickerMenu=$$v},expression:"datePickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePickerMenu = false}},model:{value:(_vm.freeSeatsDate),callback:function ($$v) {_vm.freeSeatsDate=$$v},expression:"freeSeatsDate"}})],1)]:_vm._e(),_c('v-select',{attrs:{"items":Array.from({length: (_vm.groupInfo.end_age - _vm.groupInfo.start_age)}, function (el, index){ return (_vm.groupInfo.start_age + index); }),"required":"","rules":[function (v) { return typeof v !== 'object' || 'Required!'; }],"label":"Возраст"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item)+" лет ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item)+" лет ")])]}}]),model:{value:(_vm.freeSeatsAge),callback:function ($$v) {_vm.freeSeatsAge=$$v},expression:"freeSeatsAge"}}),_c('v-text-field',{attrs:{"label":"Количество","type":"number","rules":[function (v) { return !!v || 'Required!'; }],"required":""},model:{value:(_vm.freeSeatsCount),callback:function ($$v) {_vm.freeSeatsCount=$$v},expression:"freeSeatsCount"}})],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addFreeSeatsSubmit}},[_vm._v(" Добавить ")])],1)],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-data-table',{staticClass:"elevation-1 request_list",attrs:{"headers":_vm.requestHeaders,"items":_vm.requestList,"items-per-page":15,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteSeat(item)}}},[_vm._v("mdi-delete")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }