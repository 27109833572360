<template>
	<div>
		<v-row class="ma-0">
			<h3>{{ groupInfo.name }} / {{ groupInfo.group_name }} / {{ groupInfo.hour_name }} / {{ groupInfo.lang_name }} / {{ groupInfo.ages }} лет</h3>

			<v-spacer></v-spacer>

			<v-btn color="primary" @click.stop="showAddFreeSeatsDialog">Добавить свободные места</v-btn>
		</v-row>

		<v-dialog v-model="addFreeSeatsDialog" width="500">
			<v-card>
				<v-card-title class="headline lighten-2">Добавить новые свободные места</v-card-title>
				<v-card-text>
					<v-form class="form" ref="addFreeSeatsForm">
						<v-select
							v-model="freeSeatsTypeSelected"
							:items="freeSeatsTypeList"
							item-text="name"
							item-value="id"
							:rules="[v => typeof v !== 'object' || 'Required!']"
							label="Тип свободных мест">
						</v-select>
						<template v-if="freeSeatsTypeSelected && freeSeatsTypeSelected != 1">
							<v-menu
								v-model="datePickerMenu"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="freeSeatsDate"
										label="Дата"
										readonly
										prepend-icon="mdi-calendar"
										:rules="[v => !!v || 'Required!']"
										required
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
								v-model="freeSeatsDate"
								@input="datePickerMenu = false"
								></v-date-picker>
							</v-menu>
						</template>

						<v-select
							v-model="freeSeatsAge"
							:items="Array.from({length: (groupInfo.end_age - groupInfo.start_age)}, (el, index)=> (groupInfo.start_age + index))"
							required
							:rules="[v => typeof v !== 'object' || 'Required!']"
							label="Возраст">
							<template v-slot:selection="{ item }">
								<div>
									{{ item }} лет
								</div>
							</template>
							<template v-slot:item="{ item }">
								<div>
									{{ item }} лет
								</div>
							</template>
						</v-select>
						<v-text-field
							v-model="freeSeatsCount"
							label="Количество"
							type="number"
							:rules="[v => !!v || 'Required!']"
							required
						></v-text-field>

					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="addFreeSeatsSubmit">
						Добавить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-divider class="my-4"></v-divider>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			class="elevation-1 request_list">
			<template v-slot:item.actions="{ item }">
				<v-icon  @click="deleteSeat(item)">mdi-delete</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getData();
		},
		data: () => ({
			isLoading: true,
			requestHeaders: [],
			requestList: [],
			groupInfo: {},
			addFreeSeatsDialog: false,
			freeSeatsTypeList: [],
			freeSeatsTypeSelected: null,
			freeSeatsAge: null,
			freeSeatsDate: null,
			freeSeatsCount: null,
			datePickerMenu: false
		}),
		methods: {
			/*handleClick(link) {
				console.log(link);
			},*/
			async showAddFreeSeatsDialog() {
				if(!this.freeSeatsTypeList.length) {
					await this.$http.get('/free_seats/list').then((response) => {
						if(response.data.status == 'success') {
							this.freeSeatsTypeList = response.data.data;
						}
					}).catch(() => {});
				}

				this.addFreeSeatsDialog = true;
			},
			async addFreeSeatsSubmit() {
				if(this.$refs.addFreeSeatsForm.validate()) {
					await this.$http.post('/free_seats/add', {
						"kgId": this.$route.params.id,
						"fptId": this.freeSeatsTypeSelected,
						"age": this.freeSeatsAge,
						"date": this.freeSeatsDate,
						"count": this.freeSeatsCount,
					}).then((response) => {
						if(response.data.status == 'success') {
							this.getData().then(() => {
								this.addFreeSeatsDialog = false;
							});
						} else {
							console.log(response.data.message);
						}
					}).catch(() => {});
				}
			},
			async deleteSeat(item) {
				if(confirm('R U SURE?')) {
					await this.$http.get('/free_seats/delete/'+item.id).then((response) => {
						if(response.data.status == 'success') {
							this.getData();
						}
					}).catch(() => {});
				}
			},
			async getData() {
				this.isLoading = true;
				await this.$http.get('/group/info/'+this.$route.params.id).then((response) => {
					this.requestHeaders = response.data.data.kindergartenGroupFreeSeatsList.headers;
					this.requestHeaders.push({value: 'actions', sortable: false, align: 'end'});
					this.requestList = response.data.data.kindergartenGroupFreeSeatsList.list;
					this.groupInfo = response.data.data.kindergartenGroupInfo;
				}).catch(() => {});
				this.isLoading = false;
			}
		},
		watch: {
			addFreeSeatsDialog: function (v) {
				if(!v) {
					this.$refs.addFreeSeatsForm.reset();
					/*this.freeSeatsTypeSelected = null;
					this.freeSeatsAge = null;
					this.freeSeatsDate = null;
					this.freeSeatsCount = null;*/
				}
			}

		}
	}
</script>